/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2024.

!Changelog

2024
	- [CHANGE] Change Piwik to Matomo
2019 
	20-08-2019
	- [CHANGE] Change something with _cookiewall. When undefined, still track.
	27-03-2019
	- [CHANGE] Fixed some triggers for Mamoto, that an URL is not 3 times triggered.
	- [CHANGE] 'page' to 'pageviwe' to get Analytics from Google and Mamoto the same structure.
2018
	25-05-2018
	- [ADDED] Extra check for Cookiewall 
2017
	24-07-2017
	- [CLASS] Added: _paq.push(['setCustomUrl',window.location.pathname]);
	- [CLASS] Changed: name to Piwik instead of pw.
	18-07-2017
	- [METHOD] Added 'page' variable to simple track the active page and document.title
2016
	20-10-2016
	- [BORN] This class has born.

!Usage

	matomo.track(action,key1,key2);
	
!Wishlist

	No wishes
 
*/
  
var matomo = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	
	// none
	
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************

	this.track = function(
		action,
		key1,
		key2,
		key3
	) {
		
		if(
			typeof(_cookiewall) == 'undefined' ||
			typeof(_cookiewall) != 'undefined' &&
			_cookiewall_settings[2] == 1
		) {
			
			switch(action) {
				
				case 'link':
					 _paq.push(['trackEvent',key1,key2]);
				break;
				
				case 'click':
					_paq.push(['trackEvent',key1,key2]);
				break;
				
				case 'goal':
					_paq.push(['trackGoal',key1]);
				break;
				
				case 'scroll':
					_paq.push(['trackEvent',key1]);
				break;
				
				case 'pageview':
					_paq.push(['setDocumentTitle', document.title]);
					_paq.push(['setCustomUrl', window.location.pathname]);
						
					_paq.push(['deleteCustomVariables', 'page']);
					_paq.push(['setGenerationTimeMs', 0]);
					_paq.push(['trackPageView']);
				break;
				
			}
		
		}
		
	};

}).apply(matomo);