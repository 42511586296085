/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2024.

!Changelog

2024 
	- [CHANGE] /* Edit on commenting
2018
	30-03-2018
	- [ADDED] New Static functionality is added: Image Compress
2017
	27-11-2017
	- [METHOD] Changed: The scroll to top now checks for undefined and defines settings
	08-11-2017
	- [METHOD] Changed: The scroll to top is better now
	31-10-2017
	- [METHOD] Changed: The scroll to top now checks for debug setting and for _is_debug_scrolltotop setting
	17-10-2017
	- [METHOD] Added: New variable checks if development is on. Then don't scroll to top after init();
	29-08-2017
	- [VAR] urlParts is now url[];
2016
	30-11-2016
	- [METHOD] Added: loader.change to call the loader

!Wishlist

	none

*/

/* READY **************************************************************** */
/* READY **************************************************************** */
/* READY **************************************************************** */
/* READY **************************************************************** */

$(document).ready(function() {

	if(isFirstLoad === 1) {
		firstload();
	}

});

/* FIRST LOAD **************************************************************** */
/* FIRST LOAD **************************************************************** */
/* FIRST LOAD **************************************************************** */
/* FIRST LOAD **************************************************************** */

function firstload() {

	/*
	Get URL parts
	*/
	url = getUrl();

	/*
	Bind all interaction once on first load
	*/
	windowAction.onready();
	router.onready();

	/*
	Fire specific: firstload();
	*/
	specific_interactions_firstload.onready();

	/*
	Go on and fire init
	*/
	init();

	/*
	Check if compression images is needed
	*/
	if(isCompressImages === 1) {
		$.ajax(globalUrl + '/statics/compress-images');
	}
	
	/*
	Check if cookiewall is needed
	*/
	//if(_languagesCounter === 1) {
	//	_url_one = url[1];
	//} else {
	//	_url_one = url[2];
	//}
	
	if(
		typeof(_cookiewall) != 'undefined' &&
		_cookiewall_settings[0] === 0
		//^_url_one == 'cookiewall'
	) {
		
		if(
			_cookiewall == 'bar'
		) {
			cookiewall.open('bar');
		} else {
			cookiewall.open('wall');
		}
		
		cookiewall.onready();
		
	}

	/*
	Disable firstload
	*/
	isFirstLoad = 0;

}

/* INIT **************************************************************** */
/* INIT **************************************************************** */
/* INIT **************************************************************** */
/* INIT **************************************************************** */

function init() {

	/*
	Always start at top, or when in debug check setting
	*/
	if (
		typeof _is_development === 'undefined' ||
		(_is_development && (typeof _is_development_scrolltotop !== 'undefined' && _is_development_scrolltotop === true))
	) {
		$('html,body').scrollTop(0);
	}

	/*
	Hide loader when all loaded and ready
	*/
	specific_interactions.loader(1,0);

	/*
	Get URL parts
	*/
	url = getUrl();

	/*
	Set load variables
	*/
	isLoading = 0;
	processForm = 0;

	/*
	Fire specific: init();
	*/
	specific_interactions_init.onready();

	/*
	windowAction
	*/
	windowAction.onready();

}
