/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_googlemaps = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function () {

		window.marker = null;
	
        var map;

        var supernova = new google.maps.LatLng(
			52.096125259538724,
			5.1159686974092935
		);

        var style = [
		  {
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#f5f5f5"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.icon",
		    "stylers": [
		      {
		        "visibility": "off"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#616161"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.text.stroke",
		    "stylers": [
		      {
		        "color": "#f5f5f5"
		      }
		    ]
		  },
		  {
		    "featureType": "administrative.land_parcel",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#bdbdbd"
		      }
		    ]
		  },
		  {
		    "featureType": "poi",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#eeeeee"
		      }
		    ]
		  },
		  {
		    "featureType": "poi",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#757575"
		      }
		    ]
		  },
		  {
		    "featureType": "poi.park",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#e5e5e5"
		      }
		    ]
		  },
		  {
		    "featureType": "poi.park",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#9e9e9e"
		      }
		    ]
		  },
		  {
		    "featureType": "road",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#ffffff"
		      }
		    ]
		  },
		  {
		    "featureType": "road.arterial",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#757575"
		      }
		    ]
		  },
		  {
		    "featureType": "road.highway",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#dadada"
		      }
		    ]
		  },
		  {
		    "featureType": "road.highway",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#616161"
		      }
		    ]
		  },
		  {
		    "featureType": "road.local",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#9e9e9e"
		      }
		    ]
		  },
		  {
		    "featureType": "transit.line",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#e5e5e5"
		      }
		    ]
		  },
		  {
		    "featureType": "transit.station",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#eeeeee"
		      }
		    ]
		  },
		  {
		    "featureType": "water",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#c9c9c9"
		      }
		    ]
		  },
		  {
		    "featureType": "water",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#9e9e9e"
		      }
		    ]
		  }
		];

        var mapOptions = {

            center: supernova,

            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 15,

            backgroundColor: "#fff",

            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            }

        };

        map = new google.maps.Map(document.getElementById('js-googlemaps'), mapOptions);

        // SET THE MAP TYPE
        var mapType = new google.maps.StyledMapType(style, {
            name: "Grayscale"
        });
        map.mapTypes.set('grey', mapType);
        map.setMapTypeId('grey');

        //CREATE A CUSTOM PIN ICON
        var marker_image 	= globalUrl + '/img/static/ho-googlemaps.webp';
        var pinIcon 		= new google.maps.MarkerImage(marker_image, null, null, null, new google.maps.Size(40, 40));        

        marker = new google.maps.Marker({
            position: supernova,
            map: map,
            icon: pinIcon,
            title: ''
        });

		// 	/*
		// 	Windows
		// 	*/
		var window_parking =
		'<div class="infowindow">' +
			'<strong>HANONTWERPER</strong><br>Oudegracht aan de Werf 27<br>3511 AL, Utrecht' +
		'</div>';

		var infowindow_parking = new google.maps.InfoWindow({
			content: window_parking
			
		});
	  
	  	/*
	  	Markers
	  	*/
		infowindow_parking.open(
			map, 
			marker
		);

		// Voeg CSS toe om de sluitknop te verbergen
		var style1 = document.createElement('style');
		style1.innerHTML = '.gm-ui-hover-effect { display: none !important; }';
		document.head.appendChild(style1);
		
		marker.addListener("click", function() {
			window.open('https://www.google.com/maps/dir//WERF5,+Oudegracht+aan+de+Werf+27,+3511+AL+Utrecht/@52.0959704,5.1133938,17z/data=!4m17!1m7!3m6!1s0x47c66f43e41da095:0x802ab217f9e4a595!2sWERF5!8m2!3d52.0959671!4d5.1159687!16s%2Fg%2F11clzh6q3q!4m8!1m0!1m5!1m1!1s0x47c66f43e41da095:0x802ab217f9e4a595!2m2!1d5.1159687!2d52.0959671!3e2?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D');
		});

		// marker.addListener('mouseout', function() { 
		// 	infowindow_parking.close();
		// });

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	// none

}).apply(specific_googlemaps); 