/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_firstload = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/*
		Trigger: modal and popup preloader
		*/
		images.preload();
		modal.onready();
		popup.onready();
		form.onready();
		if(pollConnection === 1) { poll_connection.onready(); }
		
		/*
		Trigger: onready classes
		*/
		specific_mobilemenu.onready();

		/*
		Visitor recording
		*/
		//specific_visitor_recording.onready();

		/*
		Back button click
		Back button click
		Back button click
		Back button click
		*/
		$(document).on('click', '.js-backbutton', function() {

			/*
			Check if history back is in the same website
			*/
			if(document.referrer.indexOf(window.location.hostname) != -1) {

				window.history.back();

			} else {

				window.location.href = globalUrl;

			}

		});

		/*
		Videoplayer
		Videoplayer
		Videoplayer
		Videoplayer
		*/
		$(document).on('click', '.js-button-play', function() {

			// Haal de video en play-knop op op basis van de knop waarop is geklikt
			var $playButton = $(this);
			var $video 		= $(this).parent().find('video');

			$playButton.hide();
		
			// Start de video en verberg de play-knop
			$video.get(0).play();
		
			// Wanneer de video eindigt, toon de play-knop opnieuw
			$video.on('ended', function() {
			  
				$playButton.show();
				$video.autoplay = false;
				$video.load();

			});

		});

		$(document).on('click', '.js-videoplayer', function() {
			
			var $video 		= $(this);

			$video.parent().find('.js-button-play').show();
			$video.autoplay = false;
			$video.load();

		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_firstload); 