/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);

		/*
		Catch and active URL
		*/
		specific_interactions.catchAndActiveURL();

		/*
		Back button
		Back button
		Back button
		Back button
		*/
		if(
			url[1] != 'project' &&
			url[1] != 'contact'
		) {
			
			$('.js-backbutton').animate({
				'left': '-10vw'
			}, 500);

		} else {

			$('.js-backbutton').animate({
				'left': '3vw'
			}, 500);

		}

		/*
		Footer
		Footer
		Footer
		Footer
		*/
		if(url[1] == 'contact') {
			$('footer').hide();
		} else {
			$('footer').show();
		}

		/*
		Google Maps
		*/
		if(url[1] == 'contact') {
			specific_googlemaps.onready();
		}

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
