/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2024.

!Changelog

2024
	10-07-2024
	- [CHANGE] formHandeling formaction can now have 0 and then it will not cancel the form
2019
	24-07-2019
	- [CHANGED] trim() added, to be sure that the error message has no spaces
2018
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_formhandling = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// none
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		this.formHandlingBefore = function(
			formname
		) {
			
			switch(formname) {
			
				default:
					
					// nothing
					
				break;
				
			}
			
		};
		
		this.formHandling = function(
			formaction,
			formname,
			data
		) {
				
			/**
			Error
			**/
			if(data.trim().substring(0,5) === 'Error') {
				
				/**
				Catch unusual PHP message
				**/
				errorMessage = data.split('§');
				
				/**
				fadeIn error message
				**/
				specific_interactions_formhandling.error(formname,errorMessage[1]);
				
				/**
				Cancel and reset the form 
				**/
				if(formaction != 0) {
					form.cancelForm(formaction);
				}
				
			/**
			Success
			**/
			} else {
				
				switch(formname) {
				
					default:
						
						// data
						// $('html,body').scrollTop(0); don't scroll to top!
				
						$(formaction).html(data);
						processForm = 0;

						/*
						Set: Popup Position
						*/
						popup.setPosition();
						
					break;
										
				}
			
			}
			
		};
		
		/**
		Callback: Radiobutton
		**/
		this.radiobuttonHandeling = function(t) {
			
			/**
			Catch the name
			**/
			var _this_radiobutton 			= $(t).data('name');
			var _this_radiobutton_value 	= $("input[name*=" + _this_radiobutton + "]").val();

			/**
			Callback
			**/
			switch(_this_radiobutton) {
				
				default:
				break;
				
			}
			
			
		};
		
		/**
		Callback: Checkbox
		**/
		this.checkboxHandeling = function(t,onoff) {
			
			/**
			Catch the name
			**/
			var _this_checkbox = $(t).data('name');
			
			/**
			Callback
			**/
			switch(_this_checkbox) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Callback: Select
		**/
		this.selectHandeling = function(t) {
			
			/**
			Catch the name
			**/
			var _this_selectbox 			= $(t).attr('name');
			var _this_selectbox_selected 	= $(t).val();
			
			/**
			Callback
			**/
			switch(_this_selectbox) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Upload Document / Image callback specifics
		**/
		this.uploaderCallback = function(
			callback,
			id,
			action,
			data
		) {
			
			switch(callback) {
				
				default:
				break;

				/*
				Accommodation: Photogallery: Insert photo
				Accommodation: Photogallery: Insert photo
				Accommodation: Photogallery: Insert photo
				Accommodation: Photogallery: Insert photo
				*/
				case 'accommodation_photogallery_photo':

					/*
					XHR: Photogallery: Save photo
					*/
					if(data != 100) {
						
						$.ajax({
							url: globalUrl + '/a/a/accommodation-photogallery-photo-insert',
							type: "POST",
							data: {
								accommodation_id 						: url[2],
								accommodation_photogallery_photo_file 	: data
							},
							headers: { 
								'go-load':1 
							}
						})
						.done(function(data) {

							/*
							Photogallery: Reload
							*/
							specific_photogallery.loadPhotos(
								'accommodation',
								url[2]
							);

						})
						.always(function() {
						});

					}

				break;
				
				/*
				Accommodation - Unit: Photogallery: Insert photo
				Accommodation - Unit: Photogallery: Insert photo
				Accommodation - Unit: Photogallery: Insert photo
				Accommodation - Unit: Photogallery: Insert photo
				*/
				case 'accommodation_unit_photogallery_photo':

					/*
					XHR: Photogallery: Save photo
					*/
					if(data != 100) {
						
						$.ajax({
							url: globalUrl + '/a/a/accommodation-unit-photogallery-photo-insert',
							type: "POST",
							data: {
								accommodation_unit_id 						: url[3],
								accommodation_unit_photogallery_photo_file 	: data
							},
							headers: { 
								'go-load':1 
							}
						})
						.done(function(data) {

							/*
							Photogallery: Reload
							*/
							specific_photogallery.loadPhotos(
								'accommodation-unit',
								url[3]
							);

						})
						.always(function() {
						});

					}

				break;
				
				/*
				Skiarea: Photogallery: Insert photo
				Skiarea: Photogallery: Insert photo
				Skiarea: Photogallery: Insert photo
				Skiarea: Photogallery: Insert photo
				*/
				case 'skiarea_photogallery_photo':

					/*
					XHR: Photogallery: Save photo
					*/
					if(data != 100) {
						
						$.ajax({
							url: globalUrl + '/a/a/skiarea-photogallery-photo-insert',
							type: "POST",
							data: {
								skiarea_id 							: url[2],
								skiarea_photogallery_photo_file 	: data
							},
							headers: { 
								'go-load':1 
							}
						})
						.done(function(data) {

							/*
							Photogallery: Reload
							*/
							specific_photogallery.loadPhotos(
								'skiarea',
								url[2]
							);

						})
						.always(function() {
						});

					}
					
				break;
				
			}
			
		};
		
		/**
		Upload Document / Image callback specifics delete
		**/
		this.uploaderCallbackDelete = function(
			callback,
			id
		) {
			
			switch(callback) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Error
		**/
		this.error = function(i,errormessage) { 
		
			switch(i) {
				
				default:
					modal.open('error',i,errormessage);
				break;
				
			}
		
		};
		
}).apply(specific_interactions_formhandling); 